<template>
  <div>
    <div>
      <div class="relative bg-gray-900">
        <!-- Decorative image and overlay -->
        <div aria-hidden="true" class="absolute inset-0 overflow-hidden">
          <img
            src="@/assets/D/12.jpg"
            alt=""
            class="w-full h-full object-center object-cover"
            data-aos="fade-in"
          />
        </div>
        <div aria-hidden="true" class="absolute inset-0 bg-black opacity-70" />

        <div
          class="relative max-w-3xl mx-auto py-16 px-6 flex flex-col items-center text-center sm:py-64 lg:px-0"
        >
          <h1
            class="text-6xl font-extrabold tracking-tight text-white lg:text-6xl"
            data-aos="fade-up"
          >
            Go to<span class="text-G"> Admin Panel</span>.
          </h1>

          <a
            href="https://app.storyblok.com/#/login"
            class="mt-8 inline-block bg-G border border-transparent rounded-md py-3 px-8 text-base font-medium text-white hover:bg-yellow-700"
            data-aos="fade-up"
            >Go to Admin Panel</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
